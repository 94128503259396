<template>
  <v-dialog v-model="isVisibleInactivateUserDialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">{{
        $t("unactivate_user_confirmation_message")
      }}</v-card-title>
      <v-card-text class="pa-0 lighten-4">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                hide-details
                aria-rowcount="2"
                v-model="user.reason"
                :label="$t('unactivate_user_reason')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeForm">{{
          $t("cancel")
        }}</v-btn>
        <v-btn color="primary" text @click="unactivateItemConfirm(user)">{{
          $t("ok")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    isVisibleInactivateUserDialog: {
      get() {
        return this.$store.state.users.isVisibleInactivateUserDialog;
      },
      set(value) {
        this.$store.commit("users/IS_VISIBLE_INACTIVATE_USER_DIALOG", value);
      },
    },

    user: {
      get() {
        return this.$store.state.users.editedUser;
      },
      set(value) {
        this.$store.commit("users/EDITED_USER", value);
      },
    },
  },
  methods: {
    closeForm() {
      this.$store.dispatch("users/closeForm");
    },
    async unactivateItemConfirm(user) {
      try {
        await this.$store.dispatch("users/updateStatus", user);
        this.$store.dispatch("users/closeForm");
        this.$store.dispatch(
          "alerts/success",
          this.$t("inactivated_successfully")
        );
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
